.app-flex-table {
  font-size: 1.4rem;
  width: 100%;
  min-width: 1rem;

  &-header,
  &-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-bottom: 1px solid map-get($colors, 'silverColor');
  }

  &-header {
    background-color: map-get($colors, 'inputBackground');
    font-weight: 700;
  }

  &-row {
    position: relative;
    transition: all 0.15s linear;
    &:hover {
      background-color: map-get($colors, 'inputBackground');
    }

    &-primary {
      background-color: map-get($colors, 'primaryLighter');

      &:hover {
        background-color: map-get($colors, 'primaryLight');
      }

      /*
      .app-table-button {
        background-color: map-get($colors, 'primaryLighter');
        border: 1px solid map-get($colors, 'primary');
        color: map-get($colors, 'primary');
      }
      */

      .app-icon-button:not(:hover) {
        background-color: white;
      }
    }

    &-warn {
      background-color: map-get($colors, 'warnLighter');

      &:hover {
        background-color: map-get($colors, 'warnLight');
      }

      /*
      .app-table-button {
        background-color: map-get($colors, 'warnLighter');
        border: 1px solid map-get($colors, 'warn');
        color: map-get($colors, 'warn');
      }
      */

      .app-icon-button:not(:hover) {
        background-color: white;
      }
    }
  }

  &-no-border &-header,
  &-no-border &-row {
    border: none;
  }

  &-no-hover &-row:hover {
    background-color: transparent;
  }

  &-wrap-group {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;

    &-no-grow {
      flex-grow: 0;
    }
  }

  &-cell {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0.5em 0.5em;
    text-align: left;
    width: 15rem; /* default */

    &-icon,
    &-invisible-icon {
      align-items: center;
      display: flex;
      position: relative;

      svg {
        @include square(2.6rem);
      }
    }

    &-invisible-icon {
      color: map-get($colors, 'inputBorder') !important;
      fill: map-get($colors, 'inputBorder');
    }
  }

  &-col-xxs {
    width: 4rem;
  }
  &-col-xs {
    width: 8rem;
  }
  &-col-s {
    width: 10rem;
  }
  &-col-m {
    width: 14rem;
  }
  &-col-l {
    width: 18rem;
    flex-grow: 5;
  }
  &-col-xl {
    width: 20rem;
    flex-grow: 10;
  }

  &-col-1-btn {
    width: 7rem;
    flex-grow: 0;
  }
  &-col-2-btn {
    width: 12rem;
    flex-grow: 0;
    display: flex;
  }

  @include media-breakpoint-up(fullHD) {
    font-size: 1.6rem;

    &-cell {
      padding-left: 0.75em;
      padding-right: 0.75em;
    }

    &-col-xxs {
      width: 4rem;
    }
    &-col-xs {
      width: 8rem;
    }
    &-col-s {
      width: 12rem;
    }
    &-col-m {
      width: 15rem;
    }
    &-col-l {
      width: 20rem;
      flex-grow: 5;
    }
    &-col-xl {
      width: 30rem;
      flex-grow: 10;
    }
  }

  &-col-auto {
    width: initial;
  }
  &-col-no-grow {
    flex-grow: 0;
  }
}
